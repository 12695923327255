<template>
  <div>
    <!--<PageDynamicBreadcrumb :custom-current-page="'Edit - ' + $route.params.offerId" :override-page-level="2" />-->
    <PageDynamicBreadcrumb />

    <PageHeader title="Offer Split Tests Editor" />

    <div>
      <div v-if="populating" class="row justify-center items-center">
        <q-spinner color="primary" size="3em" />
      </div>
      <div v-else>
        <q-form @submit="updateSplitTests">
          <!-- TODO: Add Offer Name to table title -->

          <q-card class="q-mb-md">
            <q-card-section class="q-pb-none">
              <h5 class="q-ma-none text-weight-bold">
                Offer
              </h5>
            </q-card-section>
            <q-card-section class="full-width">
              <q-select
                filled
                :model-value="offerId"
                :options="offerOptions"
                label="Select an offer to split."
                emit-value
                map-options
                :disable="submitting || type === 'edit'"
                @update:model-value="
                  val => {
                    checkUnsavedChanges(val) &&
                      (this.offerId = val) &&
                      populateSplitTests();
                  }
                "
                use-input
                input-debounce="50"
                @filter="
                  (val, update) => {
                    filterOfferOptions(val, update);
                  }
                "
              />
            </q-card-section>
          </q-card>

          <q-table
            v-show="offerId !== '' && !populatingSplitTests"
            flat
            bordered
            title="Split Tests"
            :rows="splitTests"
            :columns="columns"
            :filter="filter"
            :filter-method="filterTable"
            row-key="split_parameter_id"
            selection="multiple"
            v-model:selected="selected"
            binary-state-sort
            :hide-bottom="true"
          >
            <template v-slot:top>
              <div
                class="full-width row justify-between items-center q-px-md q-pt-sm q-pb-none"
              >
                <h5 class="q-ma-none text-weight-bold">
                  Split Tests
                </h5>
                <div class="row">
                  <q-input
                    dense
                    type="search"
                    debounce="300"
                    v-model="filter"
                    placeholder="Search"
                    class="q-ml-lg"
                  >
                    <template v-slot:append>
                      <q-icon name="search" />
                    </template>
                  </q-input>
                </div>
              </div>
            </template>

            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td>
                  <q-checkbox v-model="props.selected"></q-checkbox>
                </q-td>
                <q-td
                  key="split_parameter_id"
                  :props="props"
                  style="cursor: pointer"
                  v-hover-editable
                >
                  {{
                    props.row.split_parameter_id !== ""
                      ? splitParametersById[props.row.split_parameter_id]
                          .split_parameter
                      : "-"
                  }}
                  <q-popup-edit
                    v-model="props.row.split_parameter_id"
                    :fit="false"
                    :cover="false"
                    anchor="center left"
                    self="center left"
                    style="min-width: 300px"
                    v-slot="scope"
                  >
                    <q-select
                      autofocus
                      filled
                      v-model="props.row.split_parameter_id"
                      :options="activeSplitParameterOptions"
                      label="Split Parameter"
                      emit-value
                      map-options
                      lazy-rules
                      :rules="[
                        val =>
                          (val && val.length > 0) || 'Select a Split Parameter.'
                      ]"
                      hint="Parameter to split traffic to."
                      :disable="submitting"
                      @keyup.enter="scope.set"
                      @update:model-value="scope.set"
                    />
                  </q-popup-edit>
                </q-td>
                <q-td
                  key="split_percentage"
                  :props="props"
                  style="cursor: pointer"
                  v-hover-editable
                >
                  {{ props.row.split_percentage }}%
                  <q-popup-edit
                    auto-save
                    v-model="props.row.split_percentage"
                    :fit="false"
                    :cover="false"
                    anchor="center left"
                    self="center left"
                    v-slot="scope"
                  >
                    <q-input
                      autofocus
                      filled
                      :model-value="props.row.split_percentage"
                      label="Split Percentage"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      type="number"
                      hint="Percent of traffic to split."
                      :disable="submitting"
                      @change="
                        val => {
                          props.row.split_percentage =
                            val > 100 ? 100 : val < 0 || val === '' ? 0 : val;
                        }
                      "
                      @keyup.enter="scope.set"
                    />
                  </q-popup-edit>
                </q-td>
                <q-td>
                  <a v-if="props.row.split_parameter_id !== ''" href="javascript:void(0)" @click="testSplitParameterLink(splitParametersById[props.row.split_parameter_id].split_parameter)">Preview</a>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:bottom-row>
              <q-tr no-hover>
                <q-th>Totals</q-th>
                <q-th align="left" class="text-weight-medium text-subtle">
                  <span style="font-size: 13px">
                    {{ splitTests.length + " Active Splits" }}
                  </span>
                  <br />
                  <span style="font-size: 13px">Base Case</span>
                </q-th>
                <q-th align="left" class="text-weight-medium text-subtle">
                  <span
                    v-html="totalSplitPercentage + '%'"
                    :class="{
                      'text-negative':
                        totalSplitPercentage > 100 || totalSplitPercentage < 0
                    }"
                    style="font-size: 13px"
                  />
                  <br />
                  <span style="font-size: 13px">
                    {{ baseCaseSplitPercentage }}%
                  </span>
                </q-th>
              </q-tr>
              <q-tr no-hover>
                <q-td colspan="100%">
                  <div
                    class="full-width row justify-between items-center q-pb-none q-pl-none"
                  >
                    <div>
                      <span>
                        <q-btn
                          flat
                          color="primary"
                          label="Add New"
                          @click="addNewSplitTest"
                          :disable="disableAddNew"
                        />
                        <q-tooltip
                          v-if="disableAddNew"
                          anchor="center right"
                          self="center left"
                        >
                          All split parameters already in use.
                        </q-tooltip>
                      </span>
                      <q-btn
                        flat
                        color="subtle"
                        label="Delete"
                        :disable="selected.length === 0"
                        class="q-ml-lg"
                        @click="removeSplitTest"
                      ></q-btn>
                    </div>
                  </div>
                </q-td>
              </q-tr>
            </template>
          </q-table>

          <FormActionsSection v-show="offerId !== ''">
            <template v-slot:primary-actions>
              <div>
                <q-tooltip
                  v-if="invalidConfigurations"
                  anchor="center right"
                  self="center left"
                >
                  Invalid Split Test Configurations
                </q-tooltip>
                <q-tooltip
                  v-else-if="!hasUnsavedChanges"
                  anchor="center right"
                  self="center left"
                >
                  No edits have been made.
                </q-tooltip>
                <q-btn
                  label="Save Split Tests"
                  type="submit"
                  color="primary"
                  :disable="
                    submitting || invalidConfigurations || !hasUnsavedChanges
                  "
                  :loading="submitting"
                />
              </div>
              <q-btn
                flat
                label="Cancel"
                to="/manage/offers/split-tests"
                color="subtle"
                :disable="submitting"
              />
            </template>
            <template v-slot:secondary-actions>
              <q-btn
                v-if="type === 'edit'"
                round
                flat
                color="subtle"
                icon="delete"
                @click="promptDeleteOfferSplitTests(offerId)"
              >
                <q-tooltip anchor="center left" self="center right">
                  Delete All Split Tests
                </q-tooltip>
              </q-btn>
            </template>
          </FormActionsSection>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import FormActionsSection from "@/components/UI/FormActionsSection";
import { Dialog } from "quasar";
import ConfirmationModal from "@/components/UI/ConfirmationModal";
import ConfirmationModalDelete from "@/components/UI/ConfirmationModalDelete";
import { reactive } from "@vue/reactivity";

export default {
  name: "OfferSplitTestsEditor",
  components: {
    FormActionsSection,
    PageDynamicBreadcrumb,
    PageHeader
  },
  data() {
    return {
      offerId: "",
      splitTests: reactive([]),
      initialSplitTests: [],
      type: "create",
      submitting: false,
      populating: true,
      populatingSplitTests: false,
      columns: [
        {
          name: "split_parameter_id",
          label: "Split Parameter",
          field: "split_parameter_id",
          align: "left",
          sortable: true
        },
        {
          name: "split_percentage",
          label: "Split Percentage",
          field: "split_percentage",
          align: "left",
          sortable: true
        },
        {
          name: "preview",
          label: "",
          field: "",
          filterable: false
        }
      ],
      filter: "",
      selected: [],
      splitParameterOptions: [],
      activeSplitParameterOptions: [],
      offerOptions: []
    };
  },
  watch: {
    splitTests: {
      handler(val) {
        if (Object.keys(val).length === 0) {
          this.activeSplitParameterOptions = this.splitParameterOptions;
          return;
        }

        this.activeSplitParameterOptions = this.splitParameterOptions.map(
          option => {
            return {
              ...option,
              disable: !!val.find(
                splitTest => splitTest.split_parameter_id === option.value
              )
            };
          }
        );
      },
      deep: true
    }
  },
  computed: {
    splitParametersById() {
      return this.$store.getters["offers/splitParameters/byId"];
    },
    totalSplitPercentage() {
      let total = 0;
      this.splitTests.forEach(splitTest => {
        total += +splitTest.split_percentage;
      });
      return total;
    },
    baseCaseSplitPercentage() {
      let percentage = 100 - this.totalSplitPercentage;
      if (percentage < 0) {
        percentage = 0;
      } else if (percentage > 100) {
        percentage = 100;
      }
      return percentage;
    },
    invalidConfigurations() {
      return (
        this.offerId === "" ||
        this.totalSplitPercentage > 100 ||
        this.totalSplitPercentage < 0 ||
        this.numUnsetSplitTests > 0
      );
    },
    disableAddNew() {
      return (
        this.activeSplitParameterOptions.filter(option => option.disable)
          .length === this.activeSplitParameterOptions.length
      );
    },
    numUnsetSplitTests() {
      let numUnset = 0;
      this.splitTests.forEach(splitTest => {
        if (splitTest.split_parameter_id === "") {
          ++numUnset;
        }
      });
      return numUnset;
    },
    hasUnsavedChanges() {
      let unsaved = false;
      if (this.initialSplitTests.length !== this.splitTests.length) {
        unsaved = true;
      } else {
        this.splitTests.every((splitTest, i) => {
          if (
            this.initialSplitTests[i].split_parameter_id !==
              splitTest.split_parameter_id ||
            this.initialSplitTests[i].split_percentage !==
              splitTest.split_percentage
          ) {
            unsaved = true;
            return false;
          }
          return true;
        });
      }
      return unsaved;
    }
  },
  mounted() {
    this.$store.dispatch("offers/splitParameters/POPULATE_LIST").finally(() => {
      this.splitParameterOptions = this.generateSelectOptionsArray(
        this.$store.state.offers.splitParameters.list,
        "split_parameter",
        "id"
      );

      // TODO: If editing, only populate the one offer
      this.$store.dispatch("offers/POPULATE_LIST").finally(() => {
        this.offerOptions = this.generateSelectOptionsArray(
          this.$store.state.offers.list,
          "name",
          "id",
          (a, b) => {
            return a.id - b.id;
          }
        );

        this.allOfferOptions = this.generateSelectOptionsArray(
          this.$store.state.offers.list,
          "name",
          "id",
          (a, b) => {
            return a.id - b.id;
          }
        );

        if (this.$route.params.offerId) {
          this.type = "edit";
          this.offerId = this.$route.params.offerId;
          this.populateSplitTests();
        }
        this.populating = false;
      });
    });
  },
  methods: {
    testSplitParameterLink(splitParameter) {
      if (!this.$store.getters["brands/isSet"]) {
        this.$store.dispatch("brands/POPULATE_LIST").finally(() => {
          window.open(this.generateOfferTestLink(splitParameter), "_blank");
        });
      } else {
        window.open(this.generateOfferTestLink(splitParameter), "_blank");
      }
    },
    generateOfferTestLink(splitParameter) {
      let offer = this.$store.getters["offers/byId"][this.offerId];
      return (
          "https://" +
          this.$store.getters["brands/byId"][offer.brand_id]["subdomain"] +
          "/in.php?" +
          splitParameter
      );
    },
    populateSplitTests() {
      this.populatingSplitTests = true;
      this.splitTests = [];
      this.initialSplitTests = [];

      this.$store
        .dispatch("offers/splitTests/GET_REQUEST", {
          params: {
            offer_id: this.offerId
          }
        })
        .then(data => {
          data.forEach(splitTest => {
            this.splitTests.push({
              split_parameter_id: splitTest.split_parameter_id,
              split_percentage: splitTest.split_percentage
            });
            this.initialSplitTests.push({
              split_parameter_id: splitTest.split_parameter_id,
              split_percentage: splitTest.split_percentage
            });
          });
          this.populatingSplitTests = false;
        });
    },
    checkUnsavedChanges(offerId) {
      if (this.hasUnsavedChanges) {
        this.promptUnsavedChangesConfirmation(offerId);
        return false;
      }
      return true;
    },
    promptUnsavedChangesConfirmation(offerId) {
      Dialog.create({
        component: ConfirmationModal,
        componentProps: {
          header: "Unsaved Changes",
          message:
            "You have unsaved changes. Are you sure you want to continue?"
        }
      })
        .onOk(dialog => {
          this.offerId = offerId;
          this.populateSplitTests();
          dialog.hide();
        })
        .onCancel(dialog => {
          dialog.hide();
        });
    },
    updateSplitTests() {
      this.submitting = true;
      this.$store
        .dispatch("offers/splitTests/POST_REQUEST", {
          params: {
            offer_id: this.offerId,
            split_tests: this.splitTests
          }
        })
        .then(() => {
          this.triggerActionOutcomeAlert("save", true);
          this.$router.push({ path: "/manage/offers/split-tests" });
        })
        .catch(() => {
          this.triggerActionOutcomeAlert("save", false);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    addNewSplitTest() {
      if (this.numUnsetSplitTests === 0) {
        this.splitTests.push({
          split_parameter_id: "",
          split_percentage: 0
        });
      }
    },
    removeSplitTest() {
      this.selected.forEach(selectedSplitTest => {
        this.splitTests = this.splitTests.filter(splitTest => {
          return (
            splitTest.split_parameter_id !==
            selectedSplitTest.split_parameter_id
          );
        });
      });
      this.selected = [];
    },
    promptDeleteOfferSplitTests(offerId) {
      Dialog.create({
        component: ConfirmationModalDelete,
        componentProps: {
          descriptor:
            "all split tests associated with " +
            this.$store.getters["offers/byId"][offerId].name
        }
      }).onOk(dialog => {
        dialog.submitting = true;
        this.$store
          .dispatch("offers/splitTests/POST_REQUEST", {
            params: {
              offer_id: offerId,
              split_tests: []
            }
          })
          .then(() => {
            this.triggerActionOutcomeAlert("delete", true);
            dialog.hide();
            this.$router.push({ path: "/manage/offers/split-tests" });
          })
          .catch(() => {
            this.triggerActionOutcomeAlert("delete", false);
          })
          .finally(() => {
            dialog.submitting = false;
          });
      });
    },
    filterOfferOptions(val, update) {
      if (val === "") {
        update(() => {
          this.offerOptions = this.allOfferOptions;
        });
        return;
      }
      update(() => {
        const needle = val.toLowerCase();
        this.offerOptions = this.allOfferOptions.filter(
          option => option.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    filterTable(rows, terms) {
      let lowerTerms = terms ? terms.toLowerCase() : "";

      return rows.filter(row =>
        this.splitParametersById[row.split_parameter_id].split_parameter
          .toLowerCase()
          .includes(lowerTerms)
      );
    }
  }
};
</script>

<style scoped lang="scss"></style>
